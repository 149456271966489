import React, { useEffect, useState } from 'react';
import './PaymentOptionForm.scss';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Badge from '@material-ui/core/Badge';
import { Button, Checkbox, Link } from '@material-ui/core';
import { http, AlertService, FxInput, formValidator } from '../../fx-core';
import * as _ from 'lodash';

const PaymentOptionForm = (props) => {

  const allPaymentOptions = [
    { text: 'Cash On Delivery', value: 'cod', checked: false },
    { text: 'Razorpay', value: 'razorpay', checked: false }
    // { text: 'Bank Transfer', value: 'banktransfer', checked: false }
  ];
  const defaultContext = {
    isStripeConfigured: false
  };
  const defaultProviderCrendential = {
    accountNumber: "",
    accountName: ""
  };
  const defaultAccountInfo: any = {
    currentBalance: 0
  };
  const [paymentOptions, setPaymentOptions] = useState(allPaymentOptions);
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [providerCredential, setProviderCredential] = useState(defaultProviderCrendential);
  const [errors, setErrors] = useState({});
  const [accountInfo, setAccountInfo] = useState(defaultAccountInfo);

  const handleInputChange = (item) => {
    handleProviderCredential({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  };

  const handleCurrentContextItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleProviderCredential = (data) => {
    setProviderCredential(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handlePayoptionChange = (event, itemObj) => {
    const paymentMode = itemObj.value;
    let paymentArr = paymentOptions.map((item) => {
      if (item.value == paymentMode) {
        item.checked = event.target.checked;
      }
      return item;
    })
    setPaymentOptions(paymentArr);
    if (paymentMode == 'stripe' && !itemObj.checked) {
      getStripeConfigurations();
    }
  };

  const getProviderPaymentOptions = () => {
    let apiOptions: any = {
      url: `paymentoption/stall/${props.stallId}`
    };
    http.get(apiOptions).then(res => {
      getProviderPaymentOptionsCallback(res.data);
    })
  };

  const getProviderPaymentOptionsCallback = (res) => {
    const paymentOptions = res;
    let paymentArr = allPaymentOptions.map((item) => {
      let savedPayment = _.find(paymentOptions, { paymentOptionCode: item.value });
      if (savedPayment) {
        item.checked = savedPayment.isActive;
      }
      return item;
    });
    setPaymentOptions(paymentArr);
    getStripeConfigurations();
    getBankTransferDetails();
  };

  const getBankTransferDetails = () => {
    let apiOptions: any = {
      url: `providercredential/banktransfer/${props.ownerId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      handleProviderCredential(result);
    })
  };

  const getStripeConfigurations = () => {
    let inputData = {
      id: props.ownerId
    }
    let apiOptions: any = {
      url: `providercredential/stripeconfiguration/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      const isStripeConfigured = result.account != null ? true : false;
      handleCurrentContextItemChange({ isStripeConfigured });
    })
  };

  const getStripeAuthUrl = () => {
    let inputData = { userId: props.ownerId };
    let apiOptions: any = {
      url: `paymentoption/stripe/authurl`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let url = res.data.authUrl;
      if (url) {
        window.open(url);
      }
    })
  };

  const getValidationMap = () => {
    let validationMap = {
      accountNumber: ["required", "accnoformat"],
      accountName: ["required"]
    };
    return validationMap;
  };

  const getInputValidations = (name) => {
    let validationMap = getValidationMap();
    return validationMap[name];
  };

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidationMap();
    for (var key in validationMap) {
      let result = { name: key, value: providerCredential[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  };

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  };

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const validatePaymentOption = () => {
    let result = false;
    let activePayments = paymentOptions.filter(i => i.checked);
    if (activePayments.length > 0) {
      result = true;
      let hasBankTransfer = activePayments.filter(i => i.value == "banktransfer");
      if (hasBankTransfer.length > 0) {
        result = bulkValidate();
      }
    }
    else {
      AlertService.showErrorMsg("Please select any of the payment option");
    }
    return result;
  };

  const collectPaymentOptions = () => {
    let payOptionsList: any = [];
    for (let item of paymentOptions) {
      let newOptionsList = {
        paymentOptionCode: item.value,
        isActive: item.checked,
        userId: props.ownerId,
        stallId: props.stallId
      };
      payOptionsList.push(newOptionsList);
    }
    return payOptionsList;
  };

  const savePaymentOptions = () => {
    const isValid = validatePaymentOption();
    if (isValid) {
      const stall = {
        id: props.stallId,
        ownerId: props.ownerId
      };
      const paymentOptions = collectPaymentOptions();
      let inputData = {
        stall,
        paymentOptions,
        providerCredential
      };
      let apiOptions: any = {
        url: `paymentoption/save`,
        data: inputData
      };
      http.post(apiOptions).then(res => { savePaymentOptionsCallBack(res) });
    }
  };

  const savePaymentOptionsCallBack = (res) => {
    const result = res.data;
    if (result && result.message) {
      AlertService.showErrorMsg(result.message);
      return;
    }
    const msg = "Payment options updated successfully";
    AlertService.showSuccessMsg(msg);
    handleClose();
  };

  const handleClose = () => {
    props.handlePaymentOptionClose();
  };

  const getAccountInfo = () => {
    let apiOptions: any = {
      url: `account/user/${props.ownerId}`
    };
    http.get(apiOptions).then(res => {
      getAccountInfoCallback(res);
    })
  };

  const getAccountInfoCallback = (res: any) => {
    const result: any = res.data;
    if (result && result.id > 0) {
      setAccountInfo(result);
    }
    getProviderPaymentOptions();
  }

  useEffect(() => {
    getAccountInfo();
  }, [])

  return (
    <div className='paymentoptionform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Payment Options
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>
      <div className='p-3 pt-5'>
        {
          accountInfo.currentBalance < 100 &&(
            <p style={{color:"red"}}>
              * Please buy credit wallet to enable Cash on Delivery
            </p>
          )
        }
        {
            paymentOptions.map((itemObj, i) => (
              <div className="font-14" key={i}>
                <Checkbox name="payOption" color="primary" checked={itemObj.checked} onChange={(e) => handlePayoptionChange(e, itemObj)}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  disabled={itemObj.value == "cod" && accountInfo.currentBalance < 100}
                />
                {itemObj.text}
                {
                  itemObj.value == 'stripe' && currentContext.isStripeConfigured &&
                  <span className='pl-1'>
                    <Badge badgeContent="Configured" color="primary"></Badge>
                  </span>
                }
                <div>
                  {
                    itemObj.value == 'stripe' &&
                    <div className='pl-2'>
                      {currentContext.isStripeConfigured &&
                        <span>
                          <Link className='pointer' onClick={getStripeAuthUrl}>Click here </Link>
                          to change your stripe account
                        </span>
                      }
                      {!currentContext.isStripeConfigured &&
                        <span>
                          <Link onClick={getStripeAuthUrl}>Click here </Link>
                          to connect your stripe account
                        </span>
                      }
                    </div>
                  }
                </div>
                {
                  itemObj.value == 'banktransfer' && itemObj.checked &&
                  <div className='pt-2'>
                    <div>
                      <FxInput name="accountNumber" variant="outlined" label="Account Number" fullWidth required={true} type="number"
                        value={providerCredential.accountNumber} onValueChange={handleInputChange} />
                      {hasError("accountNumber", "required") && (
                        <div className="error">Account Number is required</div>
                      )}
                    </div>
                    <div className='pt-4'>
                      <FxInput name="accountName" variant="outlined" label="Account Name" fullWidth required={true}
                        value={providerCredential.accountName} onValueChange={handleInputChange} />
                      {hasError("accountName", "required") && (
                        <div className="error">Account Name is required</div>
                      )}
                    </div>
                  </div>
                }
              </div>
          ))
        }  
      </div>
      <div className='px-4 py-2 drawer-footer'>
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={savePaymentOptions}><b>Save</b></Button>
      </div>
    </div>
  )
};

export default PaymentOptionForm;