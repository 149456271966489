import React, { useEffect, useState } from 'react';
import './MyStallList.scss';
import MyStallForm from '../MyStallForm/MyStallForm';
import StallTimings from '../StallTimings/StallTimings';
import DeliverySlots from '../DeliverySlots/DeliverySlots';
import DeliveryLocations from '../DeliveryLocations/DeliveryLocations';
import { formatService, http, localstorage, general, AlertService, useNavigationService } from '../../fx-core';
import dummyImage from "../../../assets/images/dummy.png";
import { Button, Dialog, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import StallCouponContainer from '../StallCouponContainer/StallCouponContainer';
import PaymentOptionForm from '../PaymentOptionForm/PaymentOptionForm';
import StallServiceMap from '../StallServiceMap/StallServiceMap';
import ManageCommunities from '../ManageCommunities/ManageCommunities';
import ManageStallUsers from '../ManageStallUsers/ManageStallUsers';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  },
  deliveryDrawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '80%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '50%'
    }
  },
}));

const MyStallList = (props) => {
  const { navigate } = useNavigationService();
  const classes = styles();
  const [myStallFormOpen, setMyStallFormOpen] = useState(false);
  const [myStallList, setMyStallList] = useState([]);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [stallId, setStallId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [showPayAndDelivery, setShowPayAndDelivery] = React.useState(false);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [stallTimingsOpen, setStallTimingsOpen] = useState(false);
  const [deliverySlotsOpen, setDeliverySlotsOpen] = useState(false);
  const [deliveryLocationsOpen, setDeliveryLocationsOpen] = useState(false);
  const [stallCouponContainerOpen, setStallCouponContainerOpen] = useState(false);
  const [stallName, setStallName] = useState("");
  const [paymentOptionOpen, setPaymentOptionOpen] = useState(false);
  const [stallOwnerId, setStallOwnerId] = useState(0);
  const [stallServiceMapOpen, setStallServiceMapOpen] = useState(false);
  const [manageCommunityOpen, setManageCommunityOpen] = useState(false);
  const [manageUsersOpen, setManageUsersOpen] = useState(false);

  const getMyStallList = () => {
    let inputData = {
      communityId: communityId,
      ownerId: userId,
      currentDate: formatService.getCurrentDateForServer()
    };
    let apiOptions: any = {
      url: `stall/owner`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.stall.imageUrl = general.getImageUrl(item.stall.imagePath);
      }
      setMyStallList(result);
    })
  };

  const openMyStallForm = (id) => {
    const showDeliveryOption = getShowDelOptionFlag(id);
    setShowPayAndDelivery(showDeliveryOption);
    setStallId(id);
    setMyStallFormOpen(true);
  }

  const getShowDelOptionFlag = (stallId) => {
    let res = false;
    for (let item of myStallList) {
      if (item.stall.id == stallId) {
        for (let map of item.stallServiceMap) {
          if (map.serviceCode != "ChildCare") {
            res = true;
            break;
          }
        }
        break;
      }
    }
    return res;
  }

  const handleStallFormClose = () => {
    setMyStallFormOpen(false);
    setStallId(0);
    getMyStallList();
  }

  const confirmStallLinkMail = (itemObj) => {
    setStallId(itemObj.id);
    setConfirmOpen(true);
    setMessage("About to send personalized stall link to " + itemObj.providerEmail + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    mailStallLink();
  }

  const mailStallLink = () => {
    let apiOptions: any = {
      url: `stall/${stallId}/slug/mailstalllinkqrcode`
    };
    http.get(apiOptions).then(response => {
      AlertService.showSuccessMsg("Personalized stall link sent!");
    })
  };

  const openSubscriptions = (id) => {
    let navData = {
      targetRoute: '/provider/subscriptions/' + id + '/ms',
      targetTitle: "Provider Subscriptions",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openStallTimings = (id) => {
    setStallId(id);
    setStallTimingsOpen(true);
  }

  const handleStallTimingsClose = () => {
    setStallTimingsOpen(false);
    setStallId(0);
  }

  const openDeliverySlots = (id) => {
    setStallId(id);
    setDeliverySlotsOpen(true);
  }

  const handleDeliverySlotsClose = () => {
    setDeliverySlotsOpen(false);
    setStallId(0);
  }

  const openDeliveryLocations = (id) => {
    setStallId(id);
    setDeliveryLocationsOpen(true);
  }

  const handleDeliveryLocationsClose = () => {
    setDeliveryLocationsOpen(false);
    setStallId(0);
  }

  const openStallCouponContainer = (item) => {
    setStallId(item.id);
    setStallName(item.stallName);
    setStallCouponContainerOpen(true);
  }

  const handleStallCouponContainerClose = () => {
    setStallCouponContainerOpen(false);
    setStallId(0);
    setStallName("");
  }

  const openPaymentOptions = (item) => {
    setStallId(item.id);
    setStallOwnerId(item.ownerId);
    setPaymentOptionOpen(true);
  };

  const handlePaymentOptionClose = () => {
    setPaymentOptionOpen(false);
    setStallId(0);
    setStallOwnerId(0);
  };

  const openStallServiceServiceMap = (item) => {
    setStallId(item.id);
    setStallName(item.stallName);
    setStallServiceMapOpen(true);
  };

  const handleStallServiceMapClose = () => {
    setStallServiceMapOpen(false);
    setStallId(0);
    setStallName("");
    getMyStallList();
  };

  const openManageCommunities = (item) => {
    setStallId(item.id);
    setCommunityId(item.communityId);
    setManageCommunityOpen(true);
  };

  const handleManageCommunityClose = () => {
    setManageCommunityOpen(false);
    setStallId(0);
    setCommunityId(0);
  };

  const openAccCredits = (id) => {
    let navData = {
      targetRoute: '/provider/accounts/' + id + '/ms',
      targetTitle: "Wallet Credits",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };

  const openManageUsers = (item) => {
    setStallId(item.id);
    setManageUsersOpen(true);
  };

  const handleManageUsersClose = () => {
    setManageUsersOpen(false);
    setStallId(0);
    getMyStallList();
  };

  useEffect(() => {
    getMyStallList();
  }, []);

  return (
    <div className='mystalllist-ui pt-5'>
      <div className='row title-font align-items-center pt-4'>
        <span className='pl-5 pt-3 py-1'>Stalls</span>
      </div>
      <div className='p-2 mx-3'>
        <div className='my-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell className='text-center'>Stall Status</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myStallList.map((row: any, i) => (
                  <TableRow>
                    <TableCell className='text-center'>
                      {!row.stall.imageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                      {row.stall.imageUrl && <img src={row.stall.imageUrl} alt="Stall" width="50" height="50"></img>}
                      <div className='font-weight-bold font-12 pt-2'>
                        {row.stall.stallOpenStatus === "Open" &&
                          <span className="openClr">{row.stall.stallOpenStatus}</span>
                        }
                        {row.stall.stallOpenStatus != "Open" &&
                          <span className="closeClr">{row.stall.stallOpenStatus}</span>
                        }
                      </div>
                    </TableCell>
                    <TableCell>{row.stall.stallName}</TableCell>
                    <TableCell className='text-center'>
                      {/* <span>
                        {row.stall.stallStatus == 'Approved' &&
                          <div><img src={approvedImg} /></div>
                        }
                        {row.stall.stallStatus == 'Pending' &&
                          <div><img src={pendingImg} /></div>
                        }
                      </span> */}
                      <div className='pt-2 font-13'>{row.stall.stallStatus}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {row.stallServiceMap.map((map, i) => (
                          <div>
                            {
                              map.isActive &&
                              <span>{map.serviceName}</span>
                            }
                          </div>
                        ))
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.stall.subscriptionStatus}
                    </TableCell>
                    <TableCell>
                      {row.stall.address}
                    </TableCell>

                    <TableCell>
                      <div>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openMyStallForm(row.stall.id)}>Edit</Button>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openDeliverySlots(row.stall.id)}>Delivery Slots</Button>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openSubscriptions(row.stall.id)}>Subscriptions</Button>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openAccCredits(row.stall.id)}>Wallet Credits</Button>
                      </div>
                      <div className='pt-1 mt-2'>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openStallCouponContainer(row.stall)}>Coupons</Button>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmStallLinkMail(row.stall)}>Stall Link</Button>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openStallTimings(row.stall.id)}>Stall Timings</Button>
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openDeliveryLocations(row.stall.id)}>Delivery Fee</Button>
                      </div>
                      <div className='pt-1 mt-2'>
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openPaymentOptions(row.stall)}>Payment Options</Button>
                        {/* <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openStallServiceServiceMap(row.stall)}>Services</Button> */}
                        <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openManageCommunities(row.stall)}>Manage Communities</Button>
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openManageUsers(row.stall)}>Manage Users</Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={myStallFormOpen} onClose={handleStallFormClose}>
        <MyStallForm stallId={stallId} showPayAndDelivery={showPayAndDelivery} handleStallFormClose={handleStallFormClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallTimingsOpen} onClose={handleStallTimingsClose}>
        <StallTimings stallId={stallId} handleStallTimingsClose={handleStallTimingsClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={deliverySlotsOpen} onClose={handleDeliverySlotsClose}>
        <DeliverySlots stallId={stallId} handleDeliverySlotsClose={handleDeliverySlotsClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.deliveryDrawerWidth }} open={deliveryLocationsOpen} onClose={handleDeliveryLocationsClose}>
        <DeliveryLocations stallId={stallId} handleDeliveryLocationsClose={handleDeliveryLocationsClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallCouponContainerOpen} onClose={handleStallCouponContainerClose}>
        <StallCouponContainer stallId={stallId} stallName={stallName} handleStallCouponContainerClose={handleStallCouponContainerClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={paymentOptionOpen} onClose={handlePaymentOptionClose}>
        <PaymentOptionForm stallId={stallId} ownerId={stallOwnerId} handlePaymentOptionClose={handlePaymentOptionClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallServiceMapOpen} onClose={handleStallServiceMapClose}>
        <StallServiceMap stallId={stallId} stallName={stallName} handleStallServiceMapClose={handleStallServiceMapClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={manageCommunityOpen} onClose={handleManageCommunityClose}>
        <ManageCommunities stallId={stallId} communityId={communityId} handleManageCommunityClose={handleManageCommunityClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={manageUsersOpen} onClose={handleManageUsersClose}>
        <ManageStallUsers stallId={stallId} handleManageUsersClose={handleManageUsersClose} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default MyStallList;