import React, { useEffect, useState } from 'react';
import './ProviderBookingOrders.scss';
import { FxDateDisplay, http, AlertService, formatService, FxCurrency, FxSelect, FxPopper, FxDate, localstorage } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import StallBookingDetail from '../StallBookingDetail/StallBookingDetail';

const ProviderBookingOrders = (props: any) => {

  const initialList = [];
  let defaultFilters: any = {
    stallId: -1,
    stallBookingId: -1,
    stallBookingDate: ""
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [stalls, setStalls] = useState([]);
  const [stallBookings, setStallBookings] = useState([]);
  const [stallBookingReqs, setStallBookingReqs] = useState(initialList);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedReq, setSelectedReq] = useState({});
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [bookingReqId, setBookingReqId] = useState(0);
  const [totalSeatCount, setTotalSeatCount] = useState(0);

  const handleInputChange = (item) => {
    handleFiltersChange({ [item.name]: item.value });
    let filterObj = {
      stallId: filters.stallId,
      stallBookingId: filters.stallBookingId,
      stallBookingDate: filters.stallBookingDate
    }
    if (item.name == "stallId") {
      filterObj.stallId = item.value;
      getStallBookings({ stallId: item.value });
    }
    else if (item.name == "stallBookingId") {
      filterObj.stallBookingId = item.value;
    }
    else if (item.name == "stallBookingDate") {
      filterObj.stallBookingDate = item.value;
    }
    getStallBookingReqs(filterObj);
  };

  const handleFiltersChange = (data) => {
    setFilters(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: false }
      },
      filters: {
        userId: localstorage.getItem('userId'),
        communityId: localstorage.getItem("communityId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      initLookupCallBack(res);
    })
  };

  const initLookupCallBack = (res) => {
    let result = res.data;
    const providerStalls = result.ProviderStalls;
    setStalls(providerStalls);
    if (providerStalls.length > 0) {
      let stallId = providerStalls[0].id;
      handleInputChange({ name: 'stallId', value: stallId });
      getStallBookings({ stallId });
    }
  };

  const getStallBookings = (data) => {
    let inputData = {
      lookups: {
        StallBookings: { default: true }
      },
      filters: {
        stallId: data.stallId
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getStallBookingsCallBack(res);
    })
  };

  const getStallBookingsCallBack = (res) => {
    let result = res.data;
    setStallBookings(result.StallBookings);
  };

  const getStallBookingReqs = (data) => {
    let inputData = {
      stallId: data.stallId,
      stallBookingId: data.stallBookingId,
      stallBookingDate: formatService.getDateStringForServer(data.stallBookingDate)
    }
    let apiOptions: any = {
      url: `stallbookingrequest/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getStallBookingReqsCallBack(res);
    })
  };

  const getStallBookingReqsCallBack = (res) => {
    let result = res.data
    for (let item of result) {
      item.bookingTime = item.bookingTime ? formatService.getConvertedTimeForDisplay(item.bookingTime) : "";
      item.showMore = false;
      if (item.requestNotes && item.requestNotes.length > 20) {
        item.showMore = true;
      }
    }
    if (result.length > 0) {
      let seatCount = _.sumBy(result, "seatCount");
      setTotalSeatCount(seatCount);
    }
    else {
      setTotalSeatCount(0);
    }
    setStallBookingReqs(result);
  };

  const confirmApprove = (item) => {
    let msg = "Do you want to approve ?";
    setMessage(msg);
    setSelectedReq(item);
    setConfirmOpen(true);
    setAction("approve");
  }

  const confirmReject = (item) => {
    let msg = "Do you want to reject?";
    setMessage(msg);
    setSelectedReq(item);
    setConfirmOpen(true);
    setAction("reject");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setMessage("");
    setAction("")
    setSelectedReq({});
  };

  const onConfirmCallback = (ctx, comments) => {
    let item: any = selectedReq;
    item.comments = comments;
    if (action == "approve") {
      approveBookingReq(item);
    }
    if (action == "reject") {
      rejectBookingReq(item);
    }
    handleDialogClose();
  };

  const approveBookingReq = (item) => {
    let apiOptions = {
      url: 'stallbookingrequest/approve',
      data: item
    };
    http.post(apiOptions).then(res => {
      updateCallBack(res);
    });
  };

  const rejectBookingReq = (item) => {
    let apiOptions = {
      url: 'stallbookingrequest/reject',
      data: item
    };
    http.post(apiOptions).then(res => {
      updateCallBack(res);
    });
  };

  const updateCallBack = (res) => {
    AlertService.showSuccessMsg();
    getStallBookingReqs(filters);
  }

  const viewBookingDetail = (item: any) => {
    setOpen(true);
    setBookingReqId(item.id);
  }

  const handleClose = () => {
    setOpen(false);
    setBookingReqId(0);
  }

  useEffect(() => {
    initLookup();
  }, [])


  const body = (
    <div className="providerbookingorders-ui pt-3">
      <div className="content-container">
        <div className="content">
          <div className='pt-2'>
            <div className='row my-4 mx-2'>
              <div className='col-3'>
                <FxDate
                  name="stallBookingDate"
                  variant="outlined"
                  size="small"
                  label="Booking Date"
                  value={filters.stallBookingDate}
                  onValueChange={handleInputChange} />
              </div>
              <div className="col-3 pt-3">
                <FxSelect name="stallId" variant="outlined"
                  label="Stall"
                  options={stalls}
                  selectedValue={filters.stallId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-3 pt-3">
                <FxSelect
                  name="stallBookingId"
                  variant="outlined"
                  label="Booking Name"
                  options={stallBookings}
                  selectedValue={filters.stallBookingId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  onValueChange={handleInputChange}
                />
              </div>
              <div className='col-3 pt-4'>
                <span className='color-grey'>Total Seat Counts : </span>
                <span className='bold-text'>{totalSeatCount}</span>
              </div>
            </div>
            <div className='my-4 px-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Booking #</TableCell>
                      <TableCell>Stall</TableCell>
                      <TableCell>Booking Name</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell className='text-right'>Seat Count</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stallBookingReqs.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.bookingDate} /></TableCell>
                        <TableCell>
                          <span>
                            <i className="far fa-clock color-aaa px-1"></i>
                            {row.bookingTime}
                          </span>
                        </TableCell>
                        <TableCell>{row.bookingRequestIdentifier}</TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>{row.bookingName}</TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.contactName}</span>
                            <span className='pl-2'>{row.contactNumber}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className='row'>
                            <div className='notes'>{row.requestNotes}</div>
                            {
                              row.showMore &&
                              <div className='pl-3'>
                                <FxPopper action="More"
                                  popupId={`booking-${row.id}`}>
                                  <div className='p-3 card-style'>
                                    <div className='flex'>
                                      <span className='pl-1'>{row.requestNotes}</span>
                                    </div>
                                  </div>
                                </FxPopper>
                              </div>
                            }
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>
                          {row.seatCount}
                        </TableCell>
                        <TableCell className='text-right'>
                          <FxCurrency value={row.amount} />
                        </TableCell>
                        <TableCell>
                          {
                            row.bookingPayments.length == 0 &&
                            <span>
                              N/A
                            </span>
                          }
                          {row.bookingPayments && row.bookingPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span>
                                  Card / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {row.bookingRequestStatus}
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style mr-2'
                            onClick={() => viewBookingDetail(row)}>View</Button>
                          {
                            row.bookingRequestStatusId == 1 &&
                            <span>
                              <Button variant='outlined' size='small' className='button-style'
                                onClick={() => confirmApprove(row)}>Approve</Button>

                              <Button variant='outlined' size='small' className='button-style ml-2'
                                onClick={() => confirmReject(row)}>Reject</Button>
                            </span>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="text-center m-2">
              {stallBookingReqs && stallBookingReqs.length == 0 &&
                <span className='font-18'><b>No Stall Bookings</b></span>
              }
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={"verify"} />
        </Dialog>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <StallBookingDetail id={bookingReqId} handleClose={handleClose} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default ProviderBookingOrders;